.envira-gallery-wrap.envira-layout-creative {
  .envira-layout-creative--container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
    position: relative;

    .envira-gallery-item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      overflow: hidden;
      --row-start-offset: 0; /* Placeholder default - it will be set in JS */

      .envira-gallery-item-inner {
        .envira-title,
        .envira-caption,
				.envira-exif {
          display: none;
        }

        div.envira-lazy {
          padding-bottom: unset !important;
          height: auto;
        }

        a img {
          object-fit: cover;
          width: 100%;
          max-height: 100%;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

			.envira-proofing-selected {
				padding-bottom: 0px !important;
			}
    }

    /* Positions for 9-item pattern */
    .envira-gallery-item:nth-child(9n + 1) {
      grid-column: span 4;
      grid-row: calc(var(--row-start-offset) + 1) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 2) {
      grid-column: 6 / span 3;
      grid-row: calc(var(--row-start-offset) + 3) / span 2;
    }
    .envira-gallery-item:nth-child(9n + 3) {
      grid-column: 10 / span 3;
      grid-row: calc(var(--row-start-offset) + 2) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 4) {
      grid-column: 2 / span 3;
      grid-row: calc(var(--row-start-offset) + 6) / span 2;
    }
    .envira-gallery-item:nth-child(9n + 5) {
      grid-column: 5 / span 3;
      grid-row: calc(var(--row-start-offset) + 8) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 6) {
      grid-column: 9 / span 4;
      grid-row: calc(var(--row-start-offset) + 6) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 7) {
      grid-column: 1 / span 3;
      grid-row: calc(var(--row-start-offset) + 10) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 8) {
      grid-column: 6 / span 3;
      grid-row: calc(var(--row-start-offset) + 12) / span 3;
    }
    .envira-gallery-item:nth-child(9n + 9) {
      grid-column: 10 / span 3;
      grid-row: calc(var(--row-start-offset) + 11) / span 3;
    }
  }
}
