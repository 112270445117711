/* ==========================================================================
   Envira Gallery Styles
   ========================================================================== */

.envira-gallery-wrap:not(.envira-layout-bnb--overlay) .envira-animations-item {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

/**
* Envira Gallery - Reset
*/
.envira-gallery-wrap,
.envira-gallery-wrap *,
.envira-tags-filter-list,
.envira-tags-filter-list * {
  -moz-border-radius: 0;
  -moz-box-shadow: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-box-shadow: none;
  -o-transition: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -webkit-transition: none;
  /*list-style:none;*/
  background: none;
  border-radius: 0;
  border: 0 none;
  box-shadow: none;
  box-sizing: border-box;
  float: none;
  font-size: 100%;
  height: auto;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: static;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  transition: none;
  visibility: visible;
  width: auto;

  &.envira-gallery-no-images ul {
    display: table;
  }
}

/**
* Tags Addon
*/
.envira-tags-filter-list {
  clear: both;
  list-style: none;
  margin: 0 0 10px 0;

  li.envira-tags-filter,
  li.envira-tag-filter {
    float: left;
    margin: 0 20px 10px 0;
  }

  .envira-tag-filter-link {
    font-size: 13px;
    font-weight: bold;
  }
}

/**
* Breadcrumbs Addon
*/
.envira-breadcrumbs {
  display: block;
  margin: 0 0 20px 0;
  padding: 10px;
  background: #eee;
  clear: both;

  a {
    text-decoration: none;
  }
}

/**
* WooCommerce Addon
*/
.envira-hidden {
  display: none;
}

/**
* Main Wrapper
*/
.envira-gallery-wrap {
  width: 100%;
  margin: 0 auto 20px auto;

  /**
    * Pagination Addon
    */
  .envira-pagination {
    margin: 0 0 20px 0;
    clear: both;
  }

  .envira-pagination a.page-numbers {
    /* twenty-seveteen theme messes with this */
    display: inline-block;
  }

  .envira-pagination-previous-next-only .page-numbers.dots {
    display: none;
  }

  /**
    * Description
    */
  .envira-gallery-description {
    clear: both;

    strike,
    s,
    del {
      text-decoration: line-through;
    }

    hr {
      height: 1px;
      background-color: #000;
      margin: 5px auto;
    }

    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  /**
    * Inner Wrapper
    */
  .envira-album-public,
  .envira-gallery-public {
    width: 100%;
    margin: 0 auto 20px auto;
    box-sizing: border-box;

    /**
        * Clearing
        */
    &.envira-clear {
      clear: both;

      &:after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        line-height: 0;
        overflow: auto;
        visibility: hidden;
        zoom: 1;
      }
    }

    /**
        * Inner
        */
    .envira-gallery-item-inner {
      position: relative;

      /**
            * Dynamic Positioning
            */
      .envira-gallery-position-overlay {
        box-sizing: border-box;
        position: absolute;
        overflow: visible;
        z-index: 5;
        /**
                * Top Left
                */
        &.envira-gallery-top-left {
          top: 0;
          left: 0;
          padding: 5px 0 0 5px;
        }

        /**
                * Top Right
                */
        &.envira-gallery-top-right {
          top: 0;
          right: 0;
          padding: 5px 5px 0 0;
          text-align: right;
        }

        /**
                * Bottom Left
                */
        &.envira-gallery-bottom-left {
          bottom: 0;
          left: 0;
          padding: 0 0 5px 5px;
        }

        /**
                * Bottom Right
                */
        &.envira-gallery-bottom-right {
          bottom: 0;
          right: 0;
          padding: 0 5px 5px 0;
          text-align: right;
        }
      }
    }

    /**
        * Gallery Item
        */
    .envira-gallery-item {
      float: left;

      /**
            * Link
            */
      > .envira-gallery-link {
        display: block;
        outline: none;
        border: 0 none;
        position: relative;
      }

      /**
            * Image
            */
      img {
        float: none;
        display: block;
        margin: 0 auto;
        padding: 0;
        max-width: 100%;
      }

      /**
            * Videos
            */
      iframe,
      video {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }

    /**
        * Column Widths and Clearing
        */
    &.envira-gallery-1-columns {
      .envira-gallery-item {
        clear: both;
        width: 100%;

        .envira-gallery-image {
          width: 100%;
          height: auto;
        }
      }
    }

    &.envira-gallery-2-columns {
      .envira-gallery-item {
        width: 50%;

        &:nth-child(2n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-3-columns {
      .envira-gallery-item {
        width: 33.33%;

        &:nth-child(3n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-4-columns {
      .envira-gallery-item {
        width: 25%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        &:nth-child(4n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-5-columns {
      .envira-gallery-item {
        width: 20%;

        &:nth-child(5n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-6-columns {
      .envira-gallery-item {
        width: 16.66%;

        &:nth-child(6n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-7-columns {
      .envira-gallery-item {
        width: 14.28%;

        &:nth-child(7n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-8-columns {
      .envira-gallery-item {
        width: 12.5%;

        &:nth-child(8n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-9-columns {
      .envira-gallery-item {
        width: 11.11%;

        &:nth-child(9n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-10-columns {
      .envira-gallery-item {
        width: 10%;

        &:nth-child(10n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-11-columns {
      .envira-gallery-item {
        width: 9.09%;

        &:nth-child(11n + 1) {
          clear: both;
        }
      }
    }

    &.envira-gallery-12-columns {
      .envira-gallery-item {
        width: 8.33%;

        &:nth-child(12n + 1) {
          clear: both;
        }
      }
    }

    /**
        * Optional: Isotope
        * - if enabled, .enviratope is added to .envira-gallery-public
        * - no clearing on gallery items
        */
    &.enviratope {
      .envira-gallery-item {
        clear: none !important;
      }
    }

    /**
        * Optional: CSS Animations
        * - if enabled, .envira-gallery-css-animations is added to .envira-gallery-public
        */
    &.envira-gallery-css-animations {
      /**
            * Gallery Item
            */
      .envira-gallery-item {
        /**
                * Image
                */
        img {
          transition: all 0.2s ease-in-out;
        }

        /**
                * Image Hover
                * - Always force opacity: 1, as the CSS Opacity setting for images may be less e.g. 0.5
                */
        a:hover {
          img {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}


/**
* Main Wrapper
*/
.envira-album-public.envira-justified-gallery,
.envira-gallery-public.envira-justified-gallery {
  /**
    * Inner
    */
  .envira-gallery-item-inner {
    position: absolute;
  }

  .envira-exif {
  }
}

.envira-album-public.envira-gallery-1-columns,
.envira-album-public.envira-gallery-2-columns,
.envira-album-public.envira-gallery-3-columns,
.envira-album-public.envira-gallery-4-columns,
.envira-album-public.envira-gallery-5-columns,
.envira-album-public.envira-gallery-6-columns,
.envira-album-public.envira-gallery-7-columns,
.envira-album-public.envira-gallery-8-columns,
.envira-album-public.envira-gallery-9-columns,
.envira-album-public.envira-gallery-10-columns,
.envira-album-public.envira-gallery-11-columns,
.envira-album-public.envira-gallery-12-columns,
.envira-gallery-public.envira-gallery-1-columns,
.envira-gallery-public.envira-gallery-2-columns,
.envira-gallery-public.envira-gallery-3-columns,
.envira-gallery-public.envira-gallery-4-columns,
.envira-gallery-public.envira-gallery-5-columns,
.envira-gallery-public.envira-gallery-6-columns,
.envira-gallery-public.envira-gallery-7-columns,
.envira-gallery-public.envira-gallery-8-columns,
.envira-gallery-public.envira-gallery-9-columns,
.envira-gallery-public.envira-gallery-10-columns,
.envira-gallery-public.envira-gallery-11-columns,
.envira-gallery-public.envira-gallery-12-columns {
  .envira-title,
  .envira-caption {
    text-align: center;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    display: block; /* was inline-block but changed thanks to moving caption inside link */
    strike,
    s,
    del {
      text-decoration: line-through;
    }

    a {
      display: inline;
      font-size: 14px;
      line-height: 24px;
    }

    b,
    strong {
      font-weight: bold;
    }
  }

  .envira-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }

  .envira-caption {
    font-weight: 200;

    b,
    strong {
      font-weight: bold;
    }
  }

  a.envira-link {
    display: inline;
    font-size: 14px;
    line-height: 24px;
  }
}

.envira-album-public.envira-gallery-1-columns,
.envira-album-public.envira-gallery-2-columns,
.envira-album-public.envira-gallery-3-columns,
.envira-album-public.envira-gallery-4-columns,
.envira-album-public.envira-gallery-5-columns,
.envira-album-public.envira-gallery-6-columns,
.envira-album-public.envira-gallery-7-columns,
.envira-album-public.envira-gallery-8-columns,
.envira-album-public.envira-gallery-9-columns,
.envira-album-public.envira-gallery-10-columns,
.envira-album-public.envira-gallery-11-columns,
.envira-album-public.envira-gallery-12-columns {
  .envira-gallery-item {
    img {
      width: 100%;
    }
  }
}

/**
* RTL Support
*/
.envira-gallery-wrap.envira-gallery-rtl {
  /**
    * Inner Wrapper
    */
  .envira-album-public,
  .envira-gallery-public {
    /**
        * Gallery Item
        */
    .envira-gallery-item {
      float: right;
    }
  }
}

/**
* Mobile Landscape Size to Tablet Portrait (devices and browsers)
* - Envira Gallery Columns: If 4, 5 or 6, reduce to 3 columns. Isotope will detect change + resize automatically.
*/
@media only screen and (max-width: 767px) {
  /**
    * Main Wrapper
    */
  .envira-gallery-wrap {
    /**
        * Inner Wrapper
        */
    .envira-album-public,
    .envira-gallery-public {
      /**
            * Column Widths and Clearing
            */

      // 4, 5 + 6 cols becomes 3 cols
      &.envira-gallery-4-columns {
        .envira-gallery-item {
          width: 33%;

          &:nth-child(4n + 1) {
            clear: none;
          }

          &:nth-child(3n + 1) {
            clear: both;
          }
        }
      }

      &.envira-gallery-5-columns {
        .envira-gallery-item {
          width: 33%;

          &:nth-child(5n + 1) {
            clear: none;
          }

          &:nth-child(3n + 1) {
            clear: both;
          }
        }
      }

      &.envira-gallery-6-columns {
        .envira-gallery-item {
          width: 33%;

          &:nth-child(6n + 1) {
            clear: none;
          }

          &:nth-child(3n + 1) {
            clear: both;
          }
        }
      }

      /**
            * Optional: Isotope
            * - if enabled, .enviratope is added to .envira-gallery-public
            * - no clearing on gallery items
            */
      &.enviratope {
        .envira-gallery-item {
          clear: none !important;
        }
      }
    }
  }
}

/**
* Mobile Landscape (devices and browsers)
* - Envira Gallery Columns: Reduce to 2 column. Isotope will detect change + resize automatically.
*/
@media only screen and (max-width: 459px) {
  /**
    * Main Wrapper
    */
  .envira-gallery-wrap {
    /**
        * Inner Wrapper
        */
    .envira-album-public,
    .envira-gallery-public {
      /**
            * Column Widths and Clearing
            */

      // 2, 3, 4, 5 + 6 cols becomes 2 cols
      &.envira-gallery-2-columns,
      &.envira-gallery-3-columns,
      &.envira-gallery-4-columns,
      &.envira-gallery-5-columns,
      &.envira-gallery-6-columns {
        .envira-gallery-item {
          width: 50%;

          &:nth-child(3n + 1),
          &:nth-child(4n + 1),
          &:nth-child(5n + 1),
          &:nth-child(6n + 1) {
            clear: none;
          }

          &:nth-child(2n + 1) {
            clear: both;
          }
        }
      }

      /**
            * Optional: Isotope
            * - if enabled, .enviratope is added to .envira-gallery-public
            * - no clearing on gallery items
            */
      &.enviratope {
        .envira-gallery-item {
          clear: none !important;
        }
      }
    }
  }
}

/**
* Mobile Portrait (devices and browsers)
* - Envira Gallery Columns: Reduce to 1 column. Isotope will detect change + resize automatically.
*/
@media only screen and (max-width: 320px) {
  /**
    * Main Wrapper
    */
  .envira-gallery-wrap {
    /**
        * Inner Wrapper
        */
    .envira-album-public,
    .envira-gallery-public {
      /**
            * Column Widths and Clearing
            */

      // 1, 2, 3, 4, 5 + 6 cols becomes 2 cols
      &.envira-gallery-1-columns,
      &.envira-gallery-2-columns,
      &.envira-gallery-3-columns,
      &.envira-gallery-4-columns,
      &.envira-gallery-5-columns,
      &.envira-gallery-6-columns {
        .envira-gallery-item {
          width: 100%;

          &:nth-child(2n + 1),
          &:nth-child(3n + 1),
          &:nth-child(4n + 1),
          &:nth-child(5n + 1),
          &:nth-child(6n + 1),
          &:nth-child(7n + 1),
          &:nth-child(8n + 1),
          &:nth-child(9n + 1),
          &:nth-child(10n + 1) {
            clear: both;
          }
        }
      }

      /**
            * Optional: Isotope
            * - if enabled, .enviratope is added to .envira-gallery-public
            * - no clearing on gallery items
            */
      &.enviratope {
        .envira-gallery-item {
          clear: none !important;
        }
      }
    }
  }
}

.envira-lazy:not(img) {
  position: relative;
  height: 0;
  display: block;
}

.enviratope .envira-lazy:not(img) {
  overflow: hidden;
}

.envira-lazy:not(img) img {
  position: absolute;
  top: 0;
  left: 0;
}

.envira-gallery-1-columns .envira-lazy:not(img) img,
.envira-gallery-2-columns .envira-lazy:not(img) img,
.envira-gallery-3-columns .envira-lazy:not(img) img,
.envira-gallery-4-columns .envira-lazy:not(img) img,
.envira-gallery-5-columns .envira-lazy:not(img) img,
.envira-gallery-6-columns .envira-lazy:not(img) img,
.envira-gallery-7-columns .envira-lazy:not(img) img,
.envira-gallery-8-columns .envira-lazy:not(img) img,
.envira-gallery-9-columns .envira-lazy:not(img) img,
.envira-gallery-10-columns .envira-lazy:not(img) img,
.envira-gallery-11-columns .envira-lazy:not(img) img,
.envira-gallery-12-columns .envira-lazy:not(img) img {
  position: relative;
}

img.envira-lazy {
  width: 100%;
}

/* hide the gallery overlay by default and use JS triggers to make them appear when images load */

.envira-gallery-item.envira-lazy-load .envira-gallery-position-overlay {
  display: none;
}

/* hide the captions on legacy by default and use JS triggers to make them appear when images load */

.envira-gallery-item.envira-lazy-load .envira-title,
.envira-gallery-item.envira-lazy-load .envira-caption {
  visibility: hidden;
}

/* but show if it's an embedded video */

.envira-gallery-item.envira-video-in-gallery.envira-lazy-load .envira-title,
.envira-gallery-item.envira-video-in-gallery.envira-lazy-load .envira-caption {
  visibility: visible;
}

.envira-gallery-wrap.envira-is-mobile {
  a.envira-gallery-link .caption {
    opacity: 1 !important;
  }
}

.envira_noscript_images {
  width: 33%;
  vertical-align: middle;
  border: 0;
}

// Default pagination styles in case the theme doesn't have it like 2023.
.page-numbers {
	&.current,
	&.next,
	&.prev{
		font-weight: bold;
	}
}

@import 'envirabox';
@import 'loader';
@import 'bnb-layout';
@import 'polaroid-layout';
@import 'creative-layout';
