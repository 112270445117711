$border_radius: 15px;

.envira-gallery-wrap.envira-layout-bnb {
  .envira-layout-bnb--container {
    width: 100%;
    margin: 0 auto 20px auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .envira-gallery-item {
      aspect-ratio: 1/1;
      overflow: hidden;
    }

    .envira-gallery-item-1 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      border-top-left-radius: $border_radius;
      border-bottom-left-radius: $border_radius;
    }

    .envira-gallery-item-2 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .envira-gallery-item-3 {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
      border-top-right-radius: $border_radius;
    }

    .envira-gallery-item-4 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .envira-gallery-item-5 {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
      border-bottom-right-radius: $border_radius;
    }

    .envira-gallery-item-inner {
      height: 100%;

      & a {
        & div.envira-lazy {
          height: 100%;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;

          &:hover {
            filter: brightness(70%);
            transition: all 500ms ease;
          }
        }
      }
    }
  }

  button.envira-layout-bnb--more-link {
    position: relative;
    display: block;
    border: 1px solid #000;
    float: right;
    bottom: 70px;
    margin-bottom: -70px;
    right: 15px;
    background-color: white;
    border-radius: 5px;
    font-family: Helvetica Neue, sans-serif;
    padding: 5px 10px;
    font-size: 17px;
    cursor: pointer;
    opacity: 0;
    color: black;
    font-weight: normal;

    &:hover {
      text-decoration: none;
      transition: background-color 333ms ease-in;
      background-color: #e0e0e0;
    }

    img {
      margin: 0 5px -3px 0;
      float: left;
      padding: 0;
      width: 16px;
      height: 16px;
    }

    &--empty {
      min-height: 34px;
      margin: 0;
      padding: 0 0 2px 8px;
      min-width: 34px;
    }
  }
}

// Holds the content overlay content, should not be displayed.
.envira-gallery-bnb-overlay-content {
  display: none;
}

#envira-gallery-bnb-overlay {
  position: absolute;
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 99991; // One less than the lightbox.
  min-height: 100vh;
  top: 0;
  right: 0;
  box-shadow: 0px -2px 1px black;
  margin-top: 100vh;
  transition: all 333ms ease-in;

  &--container {
    display: block;
    width: 90%;
    max-width: 900px;
    padding: 33px 0;

    #envira-gallery-bnb-overlay-close-button {
      position: relative;
      right: 0;
      .envira-close-button {
        position: fixed;
        top: 48px;
        right: 0;
        width: 32px;
        height: 32px;
        opacity: .3;
        cursor: pointer;
        transition: opacity 300ms linear;
        background-color: transparent;
        border: none;
        z-index: 1;
        padding: 0;
        margin: 0;

        &:hover {
          opacity: 1;
        }
      }
    }

    .envira-gallery-wrap.envira-layout-bnb--overlay {
      margin: 40px 0 0 0;

      & div.envira-layout-bnb--overlay--container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        &::after {
          content: "";
          flex-grow: 999;
        }

        .envira-gallery-item {
          flex: 1 1 auto;
          height: 40vw;
          max-height: 387px;
          // this size is interesting: max-height: 24vw;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          clear: none;
          width: auto;
          float: none;

          .envira-gallery-item-inner, .envira-gallery-item-inner > a * {
            object-fit: cover;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            position: relative;
          }

          &:hover {
            .envira-gallery-item-bnb-title_caption,
            .envira-exif {
              opacity: 1;
            }
          }
          .envira-exif {
            position: absolute;
            top: 0;
            background-color: rgba(255, 255, 255, 0.58);
            color: black;
            width: 100%;
            padding: 10px 35px; // 35px to clear download and social icons.
            font-size: 95%;
            line-height: normal;
            opacity: 0;
            transition: opacity 0.33s ease-in-out;
          }
          .envira-woocommerce {
            width: 100%;
            padding: 10px 35px;
            text-align: center;
            bottom: 0;
            position: absolute;
            margin: 0;
            background-color: rgba(255,255,255,.58);
          }
          .envira-gallery-position-overlay {
            display: flex;
            flex-direction: column;

            .envira-gallery-top-left {
              form.envira-proofing-form & {
                margin-top: 30px;
                margin-left: 0;
              }
            }
          }

          .envira-social-buttons.orientation-horizontal {
            display: inline-block;
            height: auto;

            div.envira-social-network {
              clear: both;
              margin: 0 0 5px 0;
            }
          }
        }
      }

      .envira-gallery-item-bnb-title_caption {
        bottom: 0;
        position: absolute;
        background-color: #000000a1;
        color: white;
        width: 100%;
        padding: 10px 35px;
        font-size: 95%;
        line-height: normal;
        opacity: 0;
        transition: opacity 0.33s ease-in-out;
        &--with-woocommerce {
          bottom: 75px;
        }
      }

      .envira-proofing-selected {
         overflow: hidden;

        .envira-exif,
        .envira-woocommerce,
        .envira-gallery-item-bnb-title_caption,
        .envira-social-buttons,
        .envira-download-button,
        .envira-printing-button {
          display: none;
          &:hover {
            display: none;
          }
        }

        .envira-proofing-fields {
          position: absolute;
          top: 0;
          background-color: rgba(255, 255, 255, 0.58);
          color: black;
          width: 100%;
          padding: 10px 35px;
          font-size: 95%;
          line-height: normal;
        }
     	}
    }
  }
}

.envira-gallery-wrap.envira-layout-bnb--overlay form.envira-proofing-form
.envira-layout-bnb--overlay--container .envira-gallery-item-inner .envira-gallery-position-overlay.envira-gallery-top-left {
  margin-left: -5px;
  margin-top: 30px;
}

@media screen and (max-width: 1080px) {
  #envira-gallery-bnb-overlay {
    &--container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;

      & .envira-layout-bnb--overlay{
        width: 100%;
        padding: 0 20px;
      }

      #envira-gallery-bnb-overlay-close-button {
        width: 10%;
      }
    }
  }
}
